<template>
  <ul class="pagination">
    <li v-if="page !== 1">
      <button type="button" class="pagination__btn" @click="$emit('change', 1)">
        <PaginationLeftIcon />
      </button>
    </li>
    <li v-for="(p, i) in currentFive" :key="i">
      <button
        type="button"
        class="pagination__btn"
        @click="$emit('change', p)"
        :class="{ 'pagination__btn--active': page === p }"
      >
        {{ p }}
      </button>
    </li>
    <li v-if="page !== total">
      <button type="button" class="pagination__btn" @click="$emit('change', total)">
        <PaginationRightIcon />
      </button>
    </li>
  </ul>
</template>

<script>
import PaginationLeftIcon from './svg/PaginationLeftIcon.vue'
import PaginationRightIcon from './svg/PaginationRightIcon.vue'

export default {
  name: 'PaginationComponent',
  props: {
    page: Number,
    total: Number
  },
  computed: {
    currentFive () {
      let startPage, endPage
      const maxPages = 5
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1
        endPage = this.total
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1
          endPage = maxPages
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1
          endPage = this.total
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage
          endPage = this.page + maxPagesAfterCurrentPage
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i)
    }
  },
  components: {
    PaginationRightIcon,
    PaginationLeftIcon
  }
}
</script>

<style lang="stylus">
.pagination {
  display flex
  justify-content center
  align-items center
  margin-top 24px
  list-style none

  &__btn {
    background none
    border none
    min-width 24px
    height 24px
    display flex
    justify-content center
    align-items center
    box-sizing border-box
    outline none
    font-size: 16px;
    line-height: 24px;
    color: var(--color_gray_dark);
    cursor pointer

    &--active {
      color var(--color_black)
      position relative
      &:after {
        content ""
        absolute bottom left right
        margin 0 auto
        width 10px
        height 1px
        background var(--color_black)
      }
    }

    .icon {
      width 24px
      height 24px

      svg path {
        stroke var(--main_light_gray)
      }
    }
  }
}
</style>
