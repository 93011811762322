<template>
  <main class="main">
    <div class="main__inner">
      <section class="section">
        <h2>Деятельность</h2>
        <div class="filter__form-change-wrap">
          <form
            @submit.prevent="load(1)"
            ref="filterForm"
            class="filter__form filter__form-change"
            autocomplete="off"
          >
            <div class="search filter">
              <div class="search__input">
                <label for="posters_search" class="search__label">Поиск</label>
                <div class="search__input__wrap">
                  <input
                    v-model="form.title.value"
                    id="posters_search"
                    type="text"
                    placeholder="Введите слово для поиска"
                    maxlength="255"
                  />
                  <button v-if="form.title.value" type="button" @click="resetSearchInput">
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <div class="filter__item filter__date">
                <label>Диапазон дат</label>
                <div class="filter__date__inputs">
                  <div class="filter__date__input">
                    <flat-pickr v-model="form.date_start.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                    </flat-pickr>
                    <DatePickIcon />
                  </div>
                  <div class="filter__date__input">
                    <flat-pickr v-model="form.date_end.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                    </flat-pickr>
                    <DatePickIcon />
                  </div>
                </div>
              </div>
              <div class="search__btn search__btn--with-clear">
                <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
                <button type="submit" class="btn-blue_dark" :disabled="loading">
                  <LoadingIndicator v-if="loading" title="Загрузка" />
                  <template v-else>Найти</template>
                </button>
              </div>
            </div>
          </form>
          <a
            @click.prevent="changeFilterForm"
            class="filter__change-btn"
            :class="{ hide__btn: isFilterVisible }"
            href="#"
          >
            <span v-if="isFilterVisible">Скрыть фильтр</span>
            <span v-else>Поиск и фильтр по деятельности</span>
            <ArrowDownIcon />
          </a>
        </div>

        <LoadingIndicator v-if="loading" title="Загрузка" />
        <template v-else>
          <p v-if="isFilter" class="result__text">
            По вашему запросу найдено совпадений: {{ this.news.paginatorInfo ? this.news.paginatorInfo.total : 0 }}
          </p>
          <template v-if="news && news.data && news.data.length">
            <div class="activity__list">
              <router-link
                v-for="(item, index) in news.data"
                :key="index"
                :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
                class="activity__item"
              >
                <div v-if="item.title" class="_item__title">
                  {{ item.title }}
                </div>
                <div class="activity__item-date" v-if="item.date_publication">
                  <time :time="item.date_publication | robotDate">
                    {{ item.date_publication | humanDateRu }}
                  </time>
                </div>
              </router-link>
            </div>
            <PaginationComponent
              v-if="
                page !== null &&
                news.paginatorInfo.total !== null &&
                news.paginatorInfo.total > first
              "
              :total="Math.ceil(news.paginatorInfo.total / first)"
              @change="paginate"
              :page="page"
            />
          </template>
          <p v-else-if="!isFilter" class="_item__title">Новости отсутствуют</p>
        </template>
      </section>
    </div>
  </main>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import DatePickIcon from '@/components/svg/DatePickIcon.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import ArrowDownIcon from '@/components/svg/ArrowDown.vue'
import PaginationComponent from '@/components/Pagination.vue'
import PAGINATE_NEWS from '@/graphql/news/News.graphql'

export default {
  name: 'DepartmentActivityPage',
  data () {
    return {
      page: 1,
      first: 20,
      loading: false,
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        disableMobile: true
      },
      filterData: {},
      form: {
        title: {
          value: null,
          message: null
        },
        date_start: {
          value: null,
          message: null
        },
        date_end: {
          value: null,
          message: null
        }
      }
    }
  },
  created () {
    this.load()
  },
  computed: {
    news () {
      return this.$store.state.news
    },
    isFilter () {
      const keys = Object.keys(this.filterData)
      return keys && keys.length
    }
  },
  methods: {
    changeFilterForm () {
      const filter = this.$refs.filterForm
      filter.style.display = this.isFilterVisible ? 'none' : 'flex'
      this.isFilterVisible = !this.isFilterVisible
    },
    paginate (e) {
      this.page = e
      this.load()
      window.scrollTo(0, 0)
    },
    async load (page) {
      if (!this.loading) {
        this.loading = true
        this.filterData = {}
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) this.filterData[key] = this.form[key].value
        })
        if (page) this.page = page
        await this.$apollo.provider.clients.dept
          .query({
            query: PAGINATE_NEWS,
            variables: Object.assign({
              ...this.filterData,
              type_id: this.$store.state.type.CODE_ACTIVITY,
              page: this.page,
              first: this.first
            })
          })
          .then(({ data }) => {
            this.loading = false
            if (data.News) {
              this.$store.commit('news', data.News)
            }
            if (data.this_department) {
              this.$store.commit('this_department', data.this_department)
            }
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
          })
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm () {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null
      })
      this.paginate(1)
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput () {
      this.form.title.value = null
      // this.paginate(1)
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    FlatPickr,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
    PaginationComponent
  }
}
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'

.activity {
  &__list {
    display flex
    flex-direction column
    gap 32px
    +below(768px) {
      gap 24px
    }
    +below(420px) {
      gap 16px
    }
  }

  &__item {
    display flex
    align-items flex-start
    justify-content space-between
    gap 16px
    +below(420px) {
      flex-direction column
      gap 8px
    }

    &-title {
      font-weight: 500;
      font-size: 1.125em
      line-height: 24px;
    }

    &-date {
      flex-shrink 0
      font-size: 0.75em
      line-height: 18px;
      color var(--color_blue_dark)
      +below(420px) {
        margin-left auto
      }
    }
  }
}
</style>
